import React from 'react';
import type { FC } from 'react';
import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { graphql, useStaticQuery } from 'gatsby';
import type { PhoneComponentQuery } from 'graphql-types';

type ColorMode = 'light' | 'dark';

interface Props {
  color: ColorMode;
}

export const Phone: FC<Props> = Props => {
  // TODO: テーマ色一元管理 本当はThemeProviderを使うのが良い気がするがemotionを後入れしているため複雑になりそうなため愚直に書く
  const textColor: string = Props.color === 'light' ? '#496db7' : '#fff';

  const { site } = useStaticQuery<PhoneComponentQuery>(graphql`
    query PhoneComponent {
      site {
        siteMetadata {
          tel
        }
      }
    }
  `);

  const tel = site?.siteMetadata?.tel;

  return (
    <div css={phone(textColor)}>
      <FontAwesomeIcon icon={faPhone} />
      &nbsp;
      <a href={'tel:' + tel}>TEL {tel}</a>
    </div>
  );
};

const phone = (textColor: string) => css`
  color: ${textColor};

  a:hover {
    text-decoration: underline;
  }
`;
