import React from 'react';
import type { FC } from 'react';
import { css } from '@emotion/react';

type ColorMode = 'light' | 'dark';

interface Props {
  color: ColorMode;
}

export const ConsultationHours: FC<Props> = Props => {
  // TODO: テーマ色一元管理 本当はThemeProviderを使うのが良い気がするがemotionを後入れしているため複雑になりそうなため愚直に書く
  const borderColor: string = Props.color === 'light' ? '#666' : '#fff';

  return (
    <div css={consultationHours(borderColor)}>
      <table>
        <thead>
          <tr>
            <th>診療時間</th>
            <th>月</th>
            <th>火</th>
            <th>水</th>
            <th>木</th>
            <th>金</th>
            <th>土</th>
            <th>日</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>9:00〜12:00</td>
            <td>●</td>
            <td>●</td>
            <td>ー</td>
            <td>●</td>
            <td>●</td>
            <td>●</td>
            <td>●</td>
          </tr>
          <tr>
            <td>15:00〜18:00</td>
            <td>●</td>
            <td>●</td>
            <td>ー</td>
            <td>●</td>
            <td>●</td>
            <td>●</td>
            <td>ー</td>
          </tr>
        </tbody>
      </table>
      <p>
        休診日は水曜、祝日、日曜午後です。受付は診療時間終了の30分前まで承ります。
      </p>
    </div>
  );
};

const consultationHours = (borderColor: string) => css`
  table {
    border-collapse: collapse;
    text-align: left;

    thead th {
      padding: 0 1rem 1rem;
    }
    tbody td {
      padding: 0.75rem 1rem 0.75rem;
      border-top: 1px solid ${borderColor};
    }
  }

  @media (max-width: 560px) {
    table {
      padding: 0.75rem 1rem 0.75rem;
      width: 100%;
      text-align: center;

      tr {
        display: block;
        float: left;
      }
      tr td,
      tr th {
        border-left: none;
        display: block;
        padding: 0;
        border-top: none;
        border-bottom: 1px solid ${borderColor};
        height: 2rem;
        line-height: 2rem;
      }
      thead {
        display: block;
        float: left;
        width: 30%;
      }
      thead tr {
        width: 100%;
      }
      tbody {
        display: block;
        float: left;
        width: 70%;
      }
      tbody tr {
        width: 50%;
      }
      thead th:last-child,
      tbody td:last-child {
        border-bottom: none;
      }
    }
  }
`;
