import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';
import type { SeoComponentQuery } from 'graphql-types';
// eslint-disable-next-line import/no-unresolved
import { useLocation } from '@reach/router';

interface Props {
  title?: string | undefined;
  description?: string | undefined;
  keywords?: string | undefined;
}

export const SEO: FC<Props> = Props => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery<SeoComponentQuery>(graphql`
    query SEOComponent {
      site {
        siteMetadata {
          title
          description
          keywords
          siteUrl
          lang
        }
      }
    }
  `);

  const defaults = site?.siteMetadata;
  const seo = {
    // 「ページ個別タイトル | 共通タイトル１ | 共通タイトル２ | 共通タイトル３」を作る
    title: `${Props.title ? Props.title + ' | ' : ''}${defaults?.title}`,
    description: Props.description ?? defaults?.description ?? '',
    // 「ページ個別キーワード,共通キーワード１,共通キーワード２,共通キーワード３」を作る
    keywords: `${Props.keywords ? Props.keywords + ',' : ''}${
      defaults?.keywords
    }`,
    url: `${defaults?.siteUrl ?? ''}${pathname}`
  };

  return (
    <Helmet>
      <html lang={defaults?.lang ?? 'ja'} />
      <title>{seo.title}</title>
      <link rel="canonical" href={seo.url} />
      <meta name="description" content={seo.description} />
      <meta name="keywords" content={seo.keywords} />
      {/* <meta name="image" content={seo.image} /> */}
      {/* OGP */}
      {/* <meta property="og:url" content={seo.url} />
      <meta property="og:site_name" content={seo.title} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} /> */}
      {/* Twitter */}
      {/* <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={defaults.twitterName} />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} /> */}
    </Helmet>
  );
};
