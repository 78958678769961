import React from 'react';
import type { FC } from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/react';
import { ConsultationHours } from '../components/consultationHours';
import { Phone } from '../components/phone';
import { StaticImage } from 'gatsby-plugin-image';

export const Footer: FC = () => {
  return (
    <footer css={footer}>
      <div css={container}>
        <div>
          <h1>
            <Link to="/">
              <StaticImage
                src="../images/logo_w.png"
                alt="動物がんクリニック東京"
                placeholder="blurred"
                css={logoImg}
              />
            </Link>
          </h1>
          <p>
            〒135-0004 東京都江東区森下3-13-5 1F
            <br />
            <span>専用駐車場2台あります</span>
            <br />
            <span>Available&nbsp;in&nbsp;English</span>
          </p>
          <div css={phoneWrapper}>
            <Phone color={'dark'} />
          </div>
        </div>
        <div css={consultationHoursWrapper}>
          <ConsultationHours color={'dark'} />
        </div>
        <div css={row}>
          <ul css={menu}>
            <li css={menuItem}>
              <Link to="/">ホーム</Link>
            </li>
            <li css={menuItem}>
              <Link to="/about">当院について</Link>
            </li>
            <li css={menuItem}>
              <Link to="/owner">飼い主の皆様へ</Link>
            </li>
            <li css={menuItem}>
              <Link to="/doctor">獣医師の皆様へ</Link>
            </li>
            <li css={menuItem}>
              <Link to="/access">アクセス</Link>
            </li>
            <li css={menuItem}>
              <Link to="/recruit">採用情報</Link>
            </li>
            <li css={menuItem}>
              <Link
                to="https://fb.me/small.animal.oncology.service"
                target="_blank"
              >
                Facebook
              </Link>
            </li>
            <li css={menuItem}>
              <Link
                to="https://www.instagram.com/animal.cancer.cllinic.tokyo/"
                target="_blank"
              >
                Instagram
              </Link>
            </li>
            <li css={menuItem}>
              <Link
                to="https://mirpet.co.jp/app/owner/clinic/detail/136"
                target="_blank"
              >
                みるペット
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div css={copy}>&copy; 動物がんクリニック東京 All rights reserved.</div>
    </footer>
  );
};

const logoImg = css`
  width: 32vw;
  margin-bottom: 0.5rem;

  @media (max-width: 560px) {
    width: 70vw;
  }
`;

const phoneWrapper = css`
  padding: 1rem 0;
  font-size: 1.5rem;
`;

const consultationHoursWrapper = css`
  @media (max-width: 560px) {
    padding: 0 1rem;
  }
`;

const footer = css`
  margin-top: 1rem;
  background-color: #024387;
  color: #fff;
`;

const container = css`
  padding: 1rem 8rem;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  @media (max-width: 560px) {
    padding: 1rem;
  }
`;

const row = css`
  flex: 0 0 100%;
  margin-top: 1rem;
`;

const menu = css`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-top: 1px solid #024387;
  padding: 1rem 0;
  border-top: solid 1px #fff;

  @media (max-width: 560px) {
    flex-wrap: wrap;
  }
`;

const menuItem = css`
  @media (max-width: 560px) {
    flex: 0 0 50%;
    text-align: center;
  }
`;

const copy = css`
  text-align: center;
  background-color: #fff;
  color: #666;
  padding: 0.5rem 0 1rem;
`;
