import React from 'react';
import type { FC } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { css } from '@emotion/react';
import type { HeaderComponentQuery } from 'graphql-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { StaticImage } from 'gatsby-plugin-image';

export const Header: FC = () => {
  const data = useStaticQuery<HeaderComponentQuery>(graphql`
    query HeaderComponent {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const snsIconHeight = 21;

  return (
    <header>
      <h1 className="logo">
        {/* <Link to="/">{data.site.siteMetadata.title}</Link> */}
        <Link to="/">
          <StaticImage
            src="../images/logo01.png"
            alt="動物がんクリニック東京"
            placeholder="blurred"
          />
        </Link>
      </h1>
      <div className="nav">
        <div className="address">
          <p>〒135-0004 東京都江東区森下3-13-5 1F</p>
          <span className="tel">
            <FontAwesomeIcon icon={faPhone} color={'#496db7'} />
            <Link to="">TEL 03-6284-0450</Link>
          </span>
        </div>
        <ul className="pc">
          <li>
            <Link to="/">ホーム</Link>
          </li>
          <li>
            <Link to="/about">当院について</Link>
          </li>
          <li>
            <Link to="/owner">飼い主の皆様へ</Link>
          </li>
          <li>
            <Link to="/doctor">獣医師の皆様へ</Link>
          </li>
          <li>
            <Link to="/access">アクセス</Link>
          </li>
          <li>
            <Link to="/recruit">採用情報</Link>
          </li>
          <li>
            <Link
              to="https://fb.me/small.animal.oncology.service"
              target="_blank"
            >
              <StaticImage
                src="../images/fb.png"
                alt="facebook"
                placeholder="blurred"
                layout="fixed"
                height={snsIconHeight}
                css={snsIcon}
              />
            </Link>
            <Link
              to="https://www.instagram.com/animal.cancer.cllinic.tokyo/"
              target="_blank"
            >
              <StaticImage
                src="../images/ig.png"
                alt="Instagram"
                placeholder="blurred"
                layout="fixed"
                height={snsIconHeight}
                css={snsIcon}
              />
            </Link>
          </li>
        </ul>
      </div>
      <div className="hamburger-menu">
        <input type="checkbox" id="menu-btn-check" />
        <label htmlFor="menu-btn-check" className="menu-btn">
          <span></span>
        </label>
        <div className="menu-content">
          <ul>
            <li>
              <Link to="/">ホーム</Link>
            </li>
            <li>
              <Link to="/about">当院について</Link>
            </li>
            <li>
              <Link to="/owner">飼い主の皆様へ</Link>
            </li>
            <li>
              <Link to="/doctor">獣医師の皆様へ</Link>
            </li>
            <li>
              <Link to="/access">アクセス</Link>
            </li>
            <li>
              <Link to="/recruit">採用情報</Link>
            </li>
            <li>
              <Link
                to="https://fb.me/small.animal.oncology.service"
                target="_blank"
              >
                Facebook
              </Link>
            </li>
            <li>
              <Link
                to="https://www.instagram.com/animal.cancer.cllinic.tokyo/"
                target="_blank"
              >
                Instagram
              </Link>
            </li>
            <li>
              <Link
                to="https://mirpet.co.jp/app/owner/clinic/detail/136"
                target="_blank"
              >
                みるペット
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

const snsIcon = css`
  float: left;
  margin-left: 0.5rem;
`;
